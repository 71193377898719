import styled from "styled-components";
import theme from "./theme";

export const FormGroup = styled.div<{ disabled?: boolean; $error?: boolean; small?: boolean }>`
    label,
    input,
    textarea {
        display: block;
        font-family: "Inter", sans-serif;
    }

    label {
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }

    input,
    textarea,
    select {
        border-radius: 4px;
        width: 100%;
        font-size: 13px;
        padding: 2px 10px;
        height: ${({ small }) => (small ? "28px" : "40px")};
        border: 1px solid ${({ $error }) => ($error ? "#A7258B" : "#B7B7B7")};
        margin-bottom: 10px;
    }

    input:disabled,
    textarea:disabled,
    select:disabled {
        background: #dcdcdc;
        border-color: #dcdcdc;
    }

    input:focus,
    textarea:focus,
    select:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgb(254 132 24 / 25%);
    }

    input[type="range"] {
        outline: 0;
        box-shadow: 0 0 0 0;
    }

    .error {
        display: block;
        font-size: 12px;
        color: #a7258b;
        margin-bottom: 10px;
    }

    .error:before {
        content: "* ";
    }
`;

export const TextareaGroup = styled(FormGroup)<{ disabled?: boolean }>`
    height: auto;
    min-height: 40px;

    textarea {
        width: 100%;
        height: auto;
        border-radius: 8px;
        resize: vertical;
    }
`;

export const SelectFormGroup = styled(FormGroup)<{ disabled?: boolean; $error?: boolean; $small?: boolean; }>`
    select {
        appearance: none;
        box-shadow: none;
        background-image: url("/icons/icones_fotografoja-10.svg");
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-position: ${({ $small }) => $small ? "top 8px right 8px" : "top 15px right 15px"};
    }
`;

export const CurrencyContainer = styled.div<{ disabled?: boolean; error?: boolean }>`
    .currency-content {
        display: flex;
    }

    .prefix {
        background: ${theme.color.baseLighter};
        height: 2.5rem;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-top-left-radius: 1.875rem;
        border-bottom-left-radius: 1.875rem;

        span {
            margin: 0 0.2rem;
            color: #fff;
            font-size: 1rem;
            font-weight: 600;
        }
    }

    input {
        border-radius: 0;
        font-size: 13px;
        padding: 2px 10px;
        height: 2.5rem;
        margin-bottom: 10px;

        border-top-right-radius: 1.875rem;
        border-bottom-right-radius: 1.875rem;
    }

    label {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    input:disabled,
    textarea:disabled,
    select:disabled {
        background: #dcdcdc;
        border-color: #dcdcdc;
    }
`;
