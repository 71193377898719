import { createContext } from "react";
import { FormData, FieldRecord } from "./types";
import createFormElement, { FormElement } from "./createFormElement";
import createFieldElement, { FieldElement } from "./createFieldElement";

type UseFormOptions = Partial<{
    initialValue: Partial<FormData>;
    validationSchema: any;
    preventPropagation?: boolean;
    showButton?: boolean;
}>;

const createForm = (
    fields: FieldRecord,
    { initialValue = {}, validationSchema = null, preventPropagation = false, showButton = true }: UseFormOptions = {}
): [FieldElement, FormElement] => {
    const FormContext = createContext(initialValue);

    const Form = createFormElement(FormContext, fields, initialValue, validationSchema, preventPropagation, showButton);
    const Field = createFieldElement(FormContext, fields, validationSchema);

    return [Field, Form];
};

export default createForm;
