import { useContext, useCallback, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { cloneDeep } from "lodash";
import { hasOwnProperty } from "~/utils/object";
import yup from "~/utils/yup";
import { FieldRecord, FormControlContext, Schema, FormData } from "./types";

type NeededValue = string;
type TargetProp = string;

type FieldProps = {
    name: string;
    needs?: Record<NeededValue, TargetProp>;
    [key: string]: any;
};

const hasFieldError = async (validation, field: string, value: any): Promise<boolean | string> => {
    try {
        const fieldSchema = yup.reach(validation, field);
        return fieldSchema
            .validate(value)
            .then(() => false)
            .catch(err => err.message);
    } catch (e) {
        return false;
    }
};

export type FieldElement = (props: FieldProps) => JSX.Element;

const createFieldElement = (
    FormContext: FormControlContext,
    fields: FieldRecord,
    validationSchema: Schema
): FieldElement => {
    const Field = ({ name, needs = null, ...otherProps }: FieldProps) => {
        const { setForm: setData, setErrors, errors, form: data } = useContext(FormContext);

        const [dependencies, setDependencies] = useState({});

        useDeepCompareEffect(() => {
            if (needs) {
                const keys = Object.keys(needs);
                const deps = keys.reduce((acc, key) => {
                    const value = data[key];
                    acc[needs[key]] = value;
                    return acc;
                }, {});

                setDependencies(deps);
            }
        }, [needs, data]);

        if (!hasOwnProperty(fields, name)) {
            return <></>;
        }

        const handleChange = useCallback(
            (name: string, value: any) => {
                setData((f: Partial<FormData>) => ({ ...f, [name]: value }));
            },
            [setData]
        );

        const handleBlur = async (name: string, value: any, context = null) => {
            if (!validationSchema || !name) {
                return;
            }

            const hasError = await hasFieldError(validationSchema, context?.validationPath ?? name, value);

            if (context?.errorPath) {
                const { errorPath } = context;

                if (errorPath.includes("[")) {
                    setErrors((e: Partial<FormData>) => {
                        const errs = cloneDeep(e);

                        const [pathName, pathRest] = errorPath.split("[");
                        const [index, pathRest2] = pathRest.split("]");
                        const [, field] = pathRest2.split(".");

                        if (!hasOwnProperty(errs, pathName)) {
                            errs[pathName] = {};
                        }

                        if (!hasOwnProperty(errs[pathName], index)) {
                            errs[pathName][index] = {};
                        }

                        errs[pathName][index][field] = hasError;

                        return errs;
                    });
                }
            } else {
                setErrors((e: Partial<FormData>) => ({ ...e, [name]: hasError }));
            }
        };

        const Component = fields?.[name]?.component ?? "";
        const props = {
            ...otherProps,
            ...fields?.[name],
            ...dependencies,
            name,
            component: undefined,
            onChange: handleChange,
            onBlur: handleBlur,
            error: errors?.[name] ?? "",
            value: data?.[name] ?? ""
        };

        if (!Component) {
            return <></>;
        }

        return <Component {...props} />;
    };

    return Field;
};

export default createFieldElement;
