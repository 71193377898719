import React from "react";
import styled from "styled-components";

type ColorVariant = "black" | "primary" | "success" | "purple";
type ButtonDecoration = "arrow";

type ButtonProps = {
    children: any;
    small?: boolean;
    block?: boolean;
    decoration?: ButtonDecoration;
    variant?: ColorVariant;
    [key: string]: any;
};

const DecorationPatterns = {
    arrow: "../icons/icones_fotografoja-12.svg"
};

const ColorPatterns = {
    black: {
        backgroundColor: "#000",
        color: "#fff",
        hoverBackgroundColor: "#000",
        hoverColor: "#fff",
        borderColor: "transparent"
    },
    purple: {
        backgroundColor: "#5064ee",
        color: "#fff",
        hoverBackgroundColor: "#5064ee",
        hoverColor: "#fff",
        borderColor: "transparent"
    },
    primary: {
        backgroundColor: "red",
        color: "#fff",
        hoverBackgroundColor: "red",
        hoverColor: "#fff",
        borderColor: "transparent"
    },
    success: {
        backgroundColor: "#1cc88a",
        color: "#fff",
        hoverBackgroundColor: "#29e09d",
        hoverColor: "#fff",
        borderColor: "transparent"
    }
};

const ButtonInner = styled.button<ButtonProps>`
    cursor: pointer;
    margin: 0 !important;
    width: ${({ block }) => block ? "100%" : "auto"};
    padding: ${({ small }) => small ? "10px !important" : "20px 0 !important"};
    font-size: 14px !important;
    background-color: ${({ variant }) => ColorPatterns[variant].backgroundColor};
    color: ${({ variant }) => ColorPatterns[variant].color};
    border-color: ${({ variant }) => ColorPatterns[variant].borderColor};
    min-width: 120px !important;

    &:hover {
        background-color: ${({ variant }) => ColorPatterns[variant].hoverBackgroundColor};
        color: ${({ variant }) => ColorPatterns[variant].hoverColor};
    }
`;

const ButtonInnerDecorated = styled(ButtonInner)`
    &:after {
        content: "";
        position: absolute;
        background-image: ${({ decoration }) => `url("${DecorationPatterns[decoration]}")`};
        background-size: ${({ small }) => small ? "10px 10px" : "15px 15px"};
        background-repeat: no-repeat;
        background-position: center center;
        width: ${({ small }) => small ? "10px" : "15px"};
        height: ${({ small }) => small ? "10px" : "15px"};
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
`;

const Button = ({
    children,
    decoration,
    variant = "primary",
    small = false,
    block = false,
    ...props
}: ButtonProps) => {
    const ButtonComponent = decoration ? ButtonInnerDecorated : ButtonInner;

    return (
        <ButtonComponent
            {...props}
            decoration={decoration}
            variant={variant}
            block={block}
            small={small}
            className="btn"
        >
            {children}
        </ButtonComponent>
    );
};

export default Button;
