import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { ValidationError } from "yup";
import Button from "~/shared/Button";
import { toast } from "react-toastify";
import { FormData, FieldRecord, FormControlContext, Schema } from "./types";
import { hasOwnProperty } from "~/utils/object";

type FormProps = {
    onSubmit: (data: FormData) => Promise<any>;
    renderButton?: (options: { loading: boolean }) => JSX.Element;
    buttonLabel?: string;
    buttonClassname?: string;
    children: any;
    initialData?: Partial<FormData>;
    notifyChange?: (data: FormData) => void;
    preventCleaning?: boolean;
};

export type FormElement = (props: FormProps) => JSX.Element;

const createFormElement = (
    FormContext: FormControlContext,
    fields: FieldRecord,
    initialValue: Partial<FormData> = {},
    validationSchema: Schema,
    preventPropagation = false,
    showButton = true
): FormElement => {
    const Form = ({
        onSubmit,
        initialData,
        buttonLabel,
        buttonClassname,
        children,
        renderButton,
        notifyChange,
        preventCleaning = false
    }: FormProps) => {
        const [data, setData] = useState<Partial<FormData>>({ ...(initialValue || {}), ...(initialData || {}) });
        const [errors, setErrors] = useState<Partial<FormData>>({});

        const [loading, setLoading] = useState<boolean>(false);

        useDeepCompareEffect(() => {
            setData(f => ({ ...f, ...(initialData || {}) }));
        }, [initialData ?? {}]);

        useDeepCompareEffect(() => {
            if (notifyChange) {
                notifyChange(data);
            }
        }, [data]);

        const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (preventPropagation) {
                e.stopPropagation();
            }

            setLoading(true);
            if (validationSchema) {
                validationSchema
                    .validate(data, { abortEarly: false })
                    .then(() => validationSchema.cast(data))
                    .then(toSubmit => Promise.resolve(onSubmit(toSubmit)))
                    .then(() => {
                        if (!preventCleaning) {
                            setData(initialValue);
                        }
                        setErrors({});
                    })
                    .catch(err => {
                        console.log("err", err);
                        if (err.name.includes("ValidationError")) {
                            const fieldKeys = Object.keys(fields);

                            const { inner } = err;
                            const allErrors = inner.reduce((acc: Record<string, any>, e: ValidationError) => {
                                if (e.path.includes("[")) {
                                    const [pathName, pathRest] = e.path.split("[");
                                    if (fieldKeys.includes(pathName)) {
                                        const [index, pathRest2] = pathRest.split("]");
                                        const [, field] = pathRest2.split(".");

                                        if (!hasOwnProperty(acc, pathName)) {
                                            acc[pathName] = {};
                                        }

                                        if (!hasOwnProperty(acc[pathName], index)) {
                                            acc[pathName][index] = {};
                                        }

                                        if (!hasOwnProperty(acc[pathName][index], field)) {
                                            acc[pathName][index][field] = e.message;
                                        }

                                        return acc;
                                    }
                                }

                                if (!fieldKeys.includes(e.path)) {
                                    acc.general = e.message;
                                    return acc;
                                }

                                acc[e.path] = e.message;
                                return acc;
                            }, {});

                            setErrors(allErrors);
                            return;
                        }

                        console.log("context", err?.response?.data?.context);
                        if (err?.response?.data?.context?.errors) {
                            const { errors } = err.response.data.context;
                            const allErrors = Object.keys(errors).reduce((acc: Record<string, any>, key: string) => {
                                if (key.includes("[")) {
                                    const [pathName, pathRest] = key.split("[");
                                    const [index, pathRest2] = pathRest.split("]");
                                    const [, field] = pathRest2.split(".");

                                    if (!hasOwnProperty(acc, pathName)) {
                                        acc[pathName] = {};
                                    }

                                    if (!hasOwnProperty(acc[pathName], index)) {
                                        acc[pathName][index] = {};
                                    }

                                    acc[pathName][index][field] = errors[key][0];

                                    return acc;
                                }

                                acc[key] = errors[key][0];
                                return acc;
                            }, {});

                            setErrors(allErrors);
                        }

                        toast.error(`Não foi possível submeter o formulário: ${err?.response?.data?.msg ?? err.message}`);
                    })
                    .finally(() => setLoading(false));
            } else {
                onSubmit(data)
                    .catch(e => {
                        toast.error(`Não foi possível submeter o formulário: ${e?.response?.data?.msg ?? e.message}`);
                    })
                    .finally(() => setLoading(false));
            }
        };

        return (
            <FormContext.Provider value={{ form: data, errors, setForm: setData, setErrors }}>
                <form onSubmit={handleSubmit}>
                    {children}

                    {showButton ? (
                        <div className="action-section">
                            {renderButton ? (
                                renderButton({ loading })
                            ) : (
                                <div className={`${buttonClassname ? buttonClassname : "d-flex justify-content-end"}`}>
                                    <Button type="submit" style={{ maxWidth: "250px" }}>
                                        {buttonLabel ? buttonLabel : "Salvar"}
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                </form>
            </FormContext.Provider>
        );
    };

    return Form;
};

export default createFormElement;
