import * as yup from "yup";
import { hasOwnProperty } from "~/utils/object";

yup.setLocale({
    mixed: {
        required: "Campo obrigatório",
        oneOf: "Campo deve possuir um dos valores: ${values}",
        notOneOf: "Campo não deve possuir um dos valores: ${notOneOf}"
    },
    string: {
        length: "Campo deve ter um texto com comprimento de ${length}",
        min: "Campo deve ter pelo menos ${min} caracteres",
        max: "Campo deve ter no máximo ${max} caracteres",
        email: "Deve ser um email válido",
        matches: "Campo deve possuir o padrão: ${regex}",
        url: "Campo deve ser uma url válida",
        lowercase: "Campo deve ser minúsculo",
        uppercase: "Campo deve ser maiúsculo"
    },
    number: {
        cast: "Campo deve ser um número",
        min: "Campo deve conter um número maior ou igual a ${min}",
        max: "Campo deve conter um número menor ou igual a ${max}",
        lessThan: "Campo deve conter um número menor que ${lessThan}",
        moreThan: "Campo deve conter um número maior que ${moreThan}",
        notEqual: "Campo não deve conter um número igual ${notEqual}",
        positive: "Campo deve conter um número positivo",
        negative: "Campo deve conter um número negativo",
        integer: "Campo deve conter um número inteiro"
    },
    date: {
        min: ({ path, min }) => ({ key: "date.min", valeus: { path, min } }),
        max: ({ path, max }) => ({ key: "date.max", valeus: { path, max } })
    },
    object: {
        noUnknown: "object.noUnknown"
    },
    array: {
        min: ({ path, min }) => ({ key: "array.min", valeus: { path, min } }),
        max: ({ path, max }) => ({ key: "array.max", valeus: { path, max } })
    }
});

/**
 * @param {yup.ValidationError} error Erro de validação do yup
 */
export function createErrorDict(error) {
    const { inner } = error;
    const allErrors = inner.reduce((acc, e) => {
        if (e.path.includes("[")) {
            const [pathName, pathRest] = e.path.split("[");
            const [index, pathRest2] = pathRest.split("]");
            const [, field] = pathRest2.split(".");

            if (!hasOwnProperty(acc, pathName)) {
                acc[pathName] = {};
            }

            if (!hasOwnProperty(acc[pathName], index)) {
                acc[pathName][index] = {};
            }

            if (!hasOwnProperty(acc[pathName][index], field)) {
                acc[pathName][index][field] = e.message;
            }

            return acc;
        }

        acc[e.path] = e.message;
        return acc;
    }, {});

    return allErrors;
}

export default yup;
